import { Button, Form, Input, notification } from "antd";
import mainLogo from "../../assets/mainLogo.svg";
import "../Login.css";
import { useState } from "react";
import axios from "axios";
import { CheckCircleFilled, DislikeOutlined } from "@ant-design/icons";
import ForgotPasswordOtpComponent from "../ForgotPasswordOtpComponent/ForgotPasswordOtpComponent";

const ForgotComponent = ({ arg, setForgotCard }) => {
  const [otpState, setOtpSate] = useState(false);
  const [userNameOTP, setUserNameOTP] = useState(null);

  const sendLink = (values) => {
    const headers = {
      contentType: "application/json",
    };

    axios
      .post(
        `https://api.edpedia.co/v1.0/superAdmin/api/user/generate-reset-token?userName=${values?.userName}`,
        headers
      )
      .then((response) => {
        console.log("res", response);
        if (response.status === 200) {
          notification.success({
            message: " Successful",
            description: "We send OTP to your mail.",
            placement: "bottomRight",
            icon: <CheckCircleFilled style={{ color: "green" }} />,
          });
          setOtpSate(true);
          setUserNameOTP(values?.userName);
        }
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          notification.error({
            message: ` ${errorMessage}`,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        } else {
          notification.error({
            message: "An error occurred, please try again later",
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        }
      });
  };

  // const onFinishpassword = (values) => {
  //   const headers = {
  //     contentType: "application/json",
  //   };

  //   axios
  //     .post(
  //       `https://api.edpedia.co/v1.0/ulp/api/auth/forgotPassword?email=${values?.email}&userName=${values?.userName}`,
  //       headers
  //     )
  //     .then((response) => {
  //       console.log("res", response);
  //       if (response.status === 200) {
  //         notification.success({
  //           message: " Successful",
  //           description: "We send OTP to your mail.",
  //           placement: "bottomRight",
  //           icon: <CheckCircleFilled style={{ color: "green" }} />,
  //         });
  //         setOtpSate(true);
  //         setUserNameOTP(values?.userName);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         const errorMessage = error.response.data.message;
  //         notification.error({
  //           message: ` ${errorMessage}`,
  //           placement: "bottomRight",
  //           icon: <DislikeOutlined style={{ color: "red" }} />,
  //         });
  //       } else {
  //         notification.error({
  //           message: "An error occurred, please try again later",
  //           placement: "bottomRight",
  //           icon: <DislikeOutlined style={{ color: "red" }} />,
  //         });
  //       }
  //     });
  // };

  const backFunction = () => {
    setForgotCard(false);
  };
  return (
    <>
      {otpState ? (
        <>
          <ForgotPasswordOtpComponent
            userNameOTP={userNameOTP}
            arg={arg}
            setOtpSate={setOtpSate}
            setForgotCard={setForgotCard}
          />
        </>
      ) : (
        <>
          <div className="secondMain">
            <div className="second_container ">
              <img
                src={mainLogo}
                onClick={() => backFunction()}
                alt="Logo"
                className="LogoMain"
              />
              <div>
                <h4 className="headingtext">{arg}</h4>
                <div className="formContainer formContainerfor2">
                  <Form
                    name="basic"
                    labelCol={{
                      span: 10,
                    }}
                    wrapperCol={{
                      span: 18,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={sendLink}
                    autoComplete="off"
                  >
                    <Form.Item
                      name="userName"
                      rules={[
                        {
                          required: true,
                          message: "* Please enter Username",
                        },
                      ]}
                    >
                      <Input
                        placeholder="User Name"
                        className="inputCssPassword"
                      />
                    </Form.Item>
                    {/* <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "* Please enter Email",
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        placeholder="Email"
                        className="inputCssPassword"
                      />
                    </Form.Item> */}
                    {/* <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}

                    <Form.Item
                      wrapperCol={{
                        offset: 8,
                        span: 20,
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          backgroundColor: "white",
                          color: "#172b4d",
                          borderRadius: 20,
                          marginTop: 10,
                          width: 100,
                          height: 40,
                          marginRight: 30,
                        }}
                      >
                        Submit
                      </Button>
                      {/* {showError && (
                  <h5 className="errorMsg">
                    *UserName and Password didn't match
                  </h5>
                )} */}
                    </Form.Item>
                  </Form>
                  <div className="Forgotcss">
                    <p onClick={() => backFunction()}>Go To Login</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ForgotComponent;
